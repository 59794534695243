import { createContext, useState } from "react";

export let UserContext = createContext();

export default function UserContextprovider(props) {
  const [counter, setCounter] = useState(0);
  const [lan, setLan] = useState("en"); // Add state for language

  let url = `https://api.aestheticdental.org/api/v1`;

  function childrenCount() {
    setCounter(Math.random());
  }

  // Function to change the language
  const changeLanguagee = (event) => {
    setLan(event.target.value); // Update the lan state based on the selected language
  };

  return (
    <UserContext.Provider value={{ counter, childrenCount, url, lan, changeLanguagee }}>
      {props.children}
    </UserContext.Provider>
  );
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { ColorRing } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';

export default function AddImgillness({ id }) {

    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    let { url, lan } = useContext(UserContext);

    const handleImageChange = (e) => setImage(e.target.files[0]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("usertoken");
        const formData = new FormData();

        if (image) formData.append('image', image);
        formData.append('key', "doctor");

        try {
            const response = await axios.post(`${url}/dashboard/galleries?lang=${lan}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "key":"public"
                },
            });

            if (response.data.status === true) {
                toast.success(response.data.message);
                setImage(null); // Clear the selected image
            }
        } catch (error) {
            console.error('Error updating data:', error);
            toast.error('Failed to upload image. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>{t("image")}</label>
                    <input
                        type='file'
                        className='form-control popupinput mt-2'
                        onChange={handleImageChange}
                    />
                </div>

                <div className='mt-3'>
                    <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        ) : (
                            t("send")
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

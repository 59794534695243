import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../Context/Context';

export default function EditHeader({ id }) {
    const [titleAr, setTitleAr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleDe, setTitleDe] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [video, setVideo] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const { t } = useTranslation();
    let { url, lan } = useContext(UserContext);
    const [image, setImage] = useState(null);

    // Handle input changes
    const handleTitleArChange = (e) => setTitleAr(e.target.value);
    const handleTitleEnChange = (e) => setTitleEn(e.target.value);
    const handleTitleDeChange = (e) => setTitleDe(e.target.value);
    const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
    const handleDescriptionEnChange = (e) => setDescriptionEn(e.target.value);
    const handleDescriptionDeChange = (e) => setDescriptionDe(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);
    // const handleVideoChange = (e) => setVideo(e.target.files[0]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem("usertoken");
                const response = await axios.get(`${url}/dashboard/staticPage?lang=${lan}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                // Assuming the data structure: response?.data?.data.aboutHeader
                const headerData = response?.data?.data?.aboutHeader;
                setVideo(headerData)
                
                if (headerData) {
                    setTitleAr(headerData.title || '');
                    setTitleEn(headerData.title_en || '');
                    setTitleDe(headerData.title_de || '');
                    setDescriptionAr(headerData.description_ar || '');
                    setDescriptionEn(headerData.description_en || '');
                    setDescriptionDe(headerData.description_de || '');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [url, lan]);
console.log(video);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("usertoken");
        const formData = new FormData();

        formData.append('title_ar', titleAr);
        formData.append('title_en', titleEn);
        formData.append('title_de', titleDe);
        formData.append('description_ar', descriptionAr);
        formData.append('description_en', descriptionEn);
        formData.append('description_de', descriptionDe);
        if (image) formData.append('file', image);
        formData.append('_method', "put");
        formData.append('key', "AboutHeader");

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${percentCompleted}%`);
                },
            };

            const response = await axios.post(`${url}/dashboard/staticPage/${id}?lang=${lan}`, formData, config);

            if (response.data.status === true) {
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    console.log(video);
    return (
        <div className='container'>
            {isLoading ? (
                <p>{t("loading")}</p>
            ) : (
                <div className='row'>
                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'> {t("namearbic")}  </label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={titleAr}
                            onChange={handleTitleArChange}
                        />
                    </div>

                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'>  {t("nameenglesh")}  </label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={titleEn}
                            onChange={handleTitleEnChange}
                        />
                    </div>

                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'> {t("namegerman")}</label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={titleDe}
                            onChange={handleTitleDeChange}
                        />
                    </div>

                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'>  {t("descriptioninarabic")}  </label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={descriptionAr}
                            onChange={handleDescriptionArChange}
                        />
                    </div>

                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'>  {t("descriptioninenglish")}   </label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={descriptionEn}
                            onChange={handleDescriptionEnChange}
                        />
                    </div>

                    <div className='mb-3 mt-3'>
                        <label className='fw-bold'> {t("descriptioningerman")}  </label>
                        <input
                            type='text'
                            className='form-control popupinput mt-2'
                            value={descriptionDe}
                            onChange={handleDescriptionDeChange}
                        />
                    </div>
                    <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("image")} </label>
          <input
            type='file'
            className='form-control popupinput mt-2'
            onChange={handleImageChange}
          />
        </div>

                    <div className='mt-3'>
                        <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
                            {isSubmitting ? (
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                />
                            ) : (
                                t("send")
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../../Context/Context';
import { useTranslation } from 'react-i18next';

export default function Edite({ id }) {
  // حالات لتخزين القيم
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // حالة للتحقق من حالة الإرسال
  const { url, lan } = useContext(UserContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // دوال لتحديث حالة المدخلات عند تغييرها
  const handleTitleArChange = (e) => setTitleAr(e.target.value);
  const handleTitleEnChange = (e) => setTitleEn(e.target.value);
  const handleTitleDeChange = (e) => setTitleDe(e.target.value);
  const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
  const handleImageChange = (e) => setImage(e.target.files[0]);

  // دالة لإرسال البيانات إلى الـ API
  const handleSubmit = async () => {
    if (!titleAr || !titleEn || !titleDe || !descriptionAr) {
      toast.error('Please fill in all required fields.');
      return;
    }
  
    setIsSubmitting(true);
    const token = localStorage.getItem('usertoken');
    const formData = new FormData();
  
    formData.append('name', titleAr);  // هذا هو الحقل الذي يتوقعه الـ API
    formData.append('email', titleEn);
    formData.append('phone', titleDe);
    formData.append('password', descriptionAr);
    formData.append('_method', "put");
    if (image) formData.append('image', image);
  
    // طباعة البيانات للتأكد من وجودها
    console.log('Name:', formData.get('name'));
  
    try {
      const response = await axios.put(`${url}/dashboard/admin/${id}?lang=${lan}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "_method":"put"
        },
      });
  
      if (response.data.status === true) {
        toast.success(response.data.message);
        queryClient.invalidateQueries(['getshowslider', id]);
        resetForm();
      }
    } catch (error) {
      toast.error('Failed to update data. Please try again.');
      console.error('Error updating data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  // Reset form after successful submission
  const resetForm = () => {
    setTitleAr('');
    setTitleEn('');
    setTitleDe('');
    setDescriptionAr('');
    setImage(null);
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-3 mt-3'>
          <label htmlFor='name-input' className='fw-bold'>
            {t('name')}
          </label>
          <input
            id='name-input'
            type='text'
            className='form-control popupinput mt-2'
            value={titleAr}
            onChange={handleTitleArChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label htmlFor='email-input' className='fw-bold'>
            {t('email')}
          </label>
          <input
            id='email-input'
            type='email'
            className='form-control popupinput mt-2'
            value={titleEn}
            onChange={handleTitleEnChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label htmlFor='phone-input' className='fw-bold'>
            {t('phone')}
          </label>
          <input
            id='phone-input'
            type='number'
            className='form-control popupinput mt-2'
            value={titleDe}
            onChange={handleTitleDeChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label htmlFor='password-input' className='fw-bold'>
            {t('password')}
          </label>
          <input
            id='password-input'
            type='text'
            className='form-control popupinput mt-2'
            value={descriptionAr}
            onChange={handleDescriptionArChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label htmlFor='image-input' className='fw-bold'>
            {t('image')}
          </label>
          <input
            id='image-input'
            type='file'
            className='form-control popupinput mt-2'
            onChange={handleImageChange}
          />
        </div>

        <div className='mt-3'>
          <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <ColorRing
                visible={true}
                height='80'
                width='80'
                ariaLabel='color-ring-loading'
                wrapperStyle={{}}
                wrapperClass='color-ring-wrapper'
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
            ) : (
              'إرسال'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

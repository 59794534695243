import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ThreeCircles } from 'react-loader-spinner';

import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/Context';
import EditeStting from './EditeStting';
import ShowStting from './ShowStting';

const Setting = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [newSlider, setNewSlider] = useState({ title: '', description: '', image: null }); // حالة لحفظ بيانات العنصر الجديد
  const { t } = useTranslation();
  
  let { url, lan } = useContext(UserContext); // Accessing URL and language from context

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/setting?lang=${lan}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataResponse = response?.data?.data;
  
        // تأكد من تحويل البيانات إلى مصفوفة
        setData(Array.isArray(dataResponse) ? dataResponse : [dataResponse]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [url, lan]);
  
  const handleView = (item) => {
    setSelectedItem(item); // حفظ العنصر الذي سيتم تحريره أو حذفه
  };

  const handleViewDetails = (item) => {
    setViewItem(item); // حفظ العنصر الذي سيتم عرضه في نافذة العرض
  };

  const handleDeleteSuccess = (deletedId) => {
    setData(prevData => prevData.filter(item => item.id !== deletedId)); // تحديث البيانات بعد الحذف
  };

  const handleNewSliderChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setNewSlider({ ...newSlider, image: files[0] }); // حفظ الصورة
    } else {
      setNewSlider({ ...newSlider, [name]: value }); // حفظ العنوان والوصف
    }
  };

  const handleAddNewSlider = async () => {
    const formData = new FormData();
    formData.append('title', newSlider.title);
    formData.append('description', newSlider.description);
    formData.append('image', newSlider.image);

    try {
      const token = localStorage.getItem("usertoken");
      const response = await axios.post(`https://api.aestheticdental.org/api/v1/dashboard/setting?lang=en`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setData(response.data.data[0]); // إضافة العنصر الجديد إلى القائمة
    } catch (error) {
      console.error("Error adding new slider:", error);
    }
  };
console.log(data);

  const columns = [

    {
        name: 'email',
        selector: row => row.email || 'N/A',
        sortable: true,
      },
    {
      name: t("phone"),
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: t("location"),
      selector: row => row.location_ar,
      sortable: true,
    },
    {
      name: t("fecabook"),
      selector: row => row.fecabook,
      sortable: true,
    },
    {
      name: t("instgram"),
      selector: row => row.instgram,
      sortable: true,
    },
    {
      name: t("twitter"),
      selector: row => row.twitter,
      sortable: true,
    },
    {
      name: t("yotube"),
      selector: row => row.yotube,
      sortable: true,
    },
    {
      name: t("tiktok"),
      selector: row => row.tiktok,
      sortable: true,
    },


    // {
    //   name: t("logo"),
    //   selector: row => (
    //     <img src={row.logo} alt="slider" className='w-imgUserslider d-center' />
    //   ),
    //   sortable: true,
    // },
    {
      name: t("service_image"),
      selector: row => (
        <img src={row.service_image} alt="slider" className='w-imgUserslider d-center' />
      ),
      sortable: true,
    },
    {
      name: t("result_image"),
      selector: row => (
        <img src={row.result_image} alt="slider" className='w-imgUserslider d-center' />
      ),
      sortable: true,
    },
    {
      name: t("operations"),
      selector: row => (
        <>
          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#editModal"
            onClick={() => handleView(row)}
          >
            <i className="fas fa-edit"></i>
          </button>

     

          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#viewDetailsModal"
            onClick={() => handleViewDetails(row)}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  

  if (isLoading) return (
    <div className='d-center mt-5'>
      <ThreeCircles
        visible={true}
        height="100"
        width="100"
        color="#00498d"
        ariaLabel="three-circles-loading"
      />
    </div>
  );

  if (isError) return <p>Error loading data.</p>;
console.log(data);

  return (
    <div>
      {/* <div className=''>
        <button className='btn bg-color mt-3 mb-3' data-bs-toggle="modal" data-bs-target="#addNewModal">  {t("addslider")} </button>
      </div> */}
      <DataTable
        columns={columns}
        data={Array.isArray(data) ? data : []} // تأكد من أن `data` هي مصفوفة
        pagination
        selectableRows
      />

      {/* نافذة منبثقة لإضافة عنصر جديد */}
      <div className="modal fade" id="addNewModal" tabIndex="-1" aria-labelledby="addNewModalLabel" aria-hidden="true">
        <div className="modal-dialog">


          <div className="modal-content">

          
            <div className="modal-header">
              <h5 className="modal-title" id="addNewModalLabel">  {t("addslider")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>


            <div className="modal-body">
              {/* <AddSlider /> */}
            </div>
          </div>
        </div>
      </div>

      {/* باقي النوافذ المنبثقة (تحرير، عرض، حذف) */}
      <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">   {t("edittheitem")}    </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedItem && <EditeStting id={selectedItem.id} />}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="viewDetailsModal" tabIndex="-1" aria-labelledby="viewDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewDetailsModalLabel">  {t("ttemdetails")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {viewItem && <ShowStting id={viewItem.id} />}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">  {t("doyoudelete")}   </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* {selectedItem && <DeleteSlider id={selectedItem.id} onDeleteSuccess={() => handleDeleteSuccess(selectedItem.id)} />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;

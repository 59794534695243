import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; 
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';
import deTranslation from './locales/de/translation.json'; 

// Get language from localStorage or use 'ar' by default
const savedLanguage = localStorage.getItem('language') || 'ar';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
      de: { translation: deTranslation }
    },
    lng: savedLanguage, // Set initial language
    fallbackLng: 'en', // Fallback language if no translation found
    interpolation: {
      escapeValue: false, // XSS protection, no need to escape HTML
    },
  });

// Update the direction of the body based on language
const updateBodyDirection = (language) => {
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  document.body.setAttribute('dir', direction);
};

// Set initial direction when the app loads
updateBodyDirection(savedLanguage);

// Listen for language changes to dynamically update the direction and store it
i18n.on('languageChanged', (lng) => {
  updateBodyDirection(lng);
  localStorage.setItem('language', lng); // Save language change to localStorage
});

export default i18n;

import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ThreeCircles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/Context';
import DeleteContactUS from './DeleteContactUS';
import ShowContactUS from './ShowContactUS';
import Replay from './Replay';

const ContactUS = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  
  const { url, lan } = useContext(UserContext); // Accessing URL and language from context

  // Fetch Contact US data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/contact-us?lang=${lan}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, lan]);
  // console.log(data);
  

  // Handle modal view for Reply
  const handleViewDetails = (item) => {
    setViewItem(item);
  };

  // Handle delete success and remove item from the state
  const handleDeleteSuccess = (deletedId) => {
    setData((prevData) => prevData.filter(item => item.id !== deletedId));
  };

  // Table columns configuration
  const columns = [
    {
      name: t("name"),
      selector: row => row.name,
      sortable: true,
    },
    {
      name: t("email"),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: t("phone"),
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: t("subject"),
      selector: row => row.subject.split(" ").slice(0, 4).join(" "),
      sortable: true,
    },
    {
      name: t("message"),
      selector: row => row.message.split(" ").slice(0, 4).join(" "),
      sortable: true,
    },
    {
      name: t("operations"),
      selector: row => (
        <>
          {/* Replay Button */}
          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#editModal"
            onClick={() => handleViewDetails(row)}
          >
            <i className="fa fa-reply" aria-hidden="true"></i>
          </button>
          {/* <button
  className='btn btn-sm bg-color mx-1'
  onClick={() => window.open(`mailto:${row?.email}?subject=Your%20Subject&body=Your%20Message`)}
>
  <i className="fa fa-envelope" aria-hidden="true"></i>
</button> */}

          {/* <button className='btn btn-sm bg-color mx-1'> <i class="fa fa-envelope" aria-hidden="true"></i> </button> */}

          {/* Delete Button */}
          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            onClick={() => setSelectedItem(row)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>

          {/* View Details Button */}
          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#viewDetailsModal"
            onClick={() => handleViewDetails(row)}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  // Loader display
  if (isLoading) {
    return (
      <div className='d-center mt-5'>
        <ThreeCircles
          visible={true}
          height="100"
          width="100"
          color="#00498d"
          ariaLabel="three-circles-loading"
        />
      </div>
    );
  }

  // Error display
  if (isError) {
    return <div className='alert alert-danger'>{t('errorfetchingdata')}</div>;
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        selectableRows
        highlightOnHover
      />

      {/* Edit Modal with Replay */}
      <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">{t("replaycontact")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {viewItem && <Replay id={viewItem.id} />}
            </div>
          </div>
        </div>
      </div>

      {/* View Details Modal */}
      <div className="modal fade" id="viewDetailsModal" tabIndex="-1" aria-labelledby="viewDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewDetailsModalLabel">{t("ttemdetails")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {viewItem && <ShowContactUS id={viewItem.id} />}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">{t("doyoudelete")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedItem && <DeleteContactUS id={selectedItem.id} onDeleteSuccess={() => handleDeleteSuccess(selectedItem.id)} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;

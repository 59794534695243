import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/Context';

export default function ShowContactUS({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/contact-us/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>
        <div className='row'>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("name")} </p>
                    <input value={data?.name} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.name} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("email")}</p>
                    <input value={data?.email} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.email} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("phone")}</p>
                    <input value={data?.phone} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.phone} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("subject")} </p>
                    {/* <input value={data?.phone} disabled className='form-control bg-input'/> */}
                    {/* <p className='fw-bold'> {data?.subject} </p> */}
                    <textarea value={data?.subject} disabled className='form-control bg-input'  rows='4'></textarea>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("message")} </p>
                    <textarea value={data?.message} disabled className='form-control bg-input'  rows='4'></textarea>
                    {/* <p className='fw-bold'> {data?.message} </p> */}
                </div>
            </div>
       


        </div>


    </>
}

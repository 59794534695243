import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../Context/Context';
// import { UserContext } from '../../Context/Context';

export default function ShowContent({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/staticPage/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*((youtu\.be\/)|(.+\/v\/)|(.+\/embed\/)|(.+\/watch\?v=))((\w|-){11})/;
        const match = url.match(regExp);
        return match && match[6] ? match[6] : null; // إرجاع معرف الفيديو أو null إذا لم يكن صالحًا
    };
    
    return <>


        <div className='row'>
        <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("titlearabic")}</p>
                    <input value={data?.title_ar} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.title_ar} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("descriptioninarabic")} </p>
                    <textarea  rows='4' value={data?.description_ar} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.description_ar} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("titleenglish")}</p>
                    <input value={data?.title_en} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.title_en} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("descriptioninenglish")} </p>
                    <textarea  rows='4' value={data?.description_en} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.description_en} </p> */}
                </div>
            </div>
        
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("totlegerman")}</p>
                    <input value={data?.title_de} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.title_de} </p> */}
                </div>
            </div>

            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("descriptioningerman")}</p>
                    <textarea  rows='4' value={data?.description_de} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.description_de} </p> */}
                </div>
            </div>
        
            <div className='col-12 mt-3'>
    <div className='d-flex justify-content-between'>
        {/* Video element */}
        <iframe
    className='w-100 sliderimg d-center'
    width="560" // يمكنك ضبط العرض حسب الحاجة
    height="315" // يمكنك ضبط الارتفاع حسب الحاجة
    src={`https://www.youtube.com/embed/${getYouTubeVideoId(data?.file)}`} // استخدام دالة لاستخراج معرف الفيديو من الرابط
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
></iframe>

    </div>
</div>

        </div>


    </>
}

import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../Context/Context';

export default function EditContent({ id }) {
    const [titleAr, setTitleAr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleDe, setTitleDe] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [videoURL, setVideoURL] = useState(''); // تعديل لاستقبال رابط الفيديو
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    let { url, lan } = useContext(UserContext);

    // Fetch the content when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("usertoken");
            try {
                const response = await axios.get(`${url}/dashboard/staticPage/${id}?lang=${lan}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data.data;

                // تعيين القيم المسترجعة من الـ API إلى الحقول
                setTitleAr(data.title_ar || '');
                setTitleEn(data.title_en || '');
                setTitleDe(data.title_de || '');
                setDescriptionAr(data.description_ar || '');
                setDescriptionEn(data.description_en || '');
                setDescriptionDe(data.description_de || '');
                setVideoURL(data.file || ''); // تعيين قيمة data.file إلى حقل إدخال الفيديو
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id, url, lan]); // Dependencies

    const handleTitleArChange = (e) => setTitleAr(e.target.value);
    const handleTitleEnChange = (e) => setTitleEn(e.target.value);
    const handleTitleDeChange = (e) => setTitleDe(e.target.value);
    const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
    const handleDescriptionEnChange = (e) => setDescriptionEn(e.target.value);
    const handleDescriptionDeChange = (e) => setDescriptionDe(e.target.value);
    const handleVideoURLChange = (e) => setVideoURL(e.target.value); // تعديل لتغيير رابط الفيديو

    // دالة لإرسال البيانات بشكل غير متزامن
    const handleSubmit = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("usertoken");
        const formData = new FormData();

        formData.append('title_ar', titleAr);
        formData.append('title_en', titleEn);
        formData.append('title_de', titleDe);
        formData.append('description_ar', descriptionAr);
        formData.append('description_en', descriptionEn);
        formData.append('description_de', descriptionDe);
        formData.append('file', videoURL); // سيتم إرسال رابط الفيديو من data.file
        formData.append('_method', "put");
        formData.append('key', "Content");

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            };

            const response = await axios.post(`${url}/dashboard/staticPage/${id}?lang=${lan}`, formData, config);

            if (response.data.status === true) {
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='container'>
            <div className='row'>
                {/* الحقول المختلفة */}
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("namearbic")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleAr}
                        onChange={handleTitleArChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>  {t("nameenglesh")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleEn}
                        onChange={handleTitleEnChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("namegerman")}</label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleDe}
                        onChange={handleTitleDeChange}
                    />
                </div>
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("descriptioninarabic")} </label>
                    <textarea
                        className='form-control popupinput mt-2'
                        value={descriptionAr}
                        onChange={handleDescriptionArChange}
                        style={{ height: '200px' }} // تحديد الارتفاع باستخدام CSS
                        rows="8" // اختيار عدد الأسطر اختياري هنا
                    />

                </div>


                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>  {t("descriptioninenglish")}   </label>
                    <textarea
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionEn}
                        onChange={handleDescriptionEnChange}
                        style={{ height: '200px' }} // تحديد الارتفاع باستخدام CSS
                        rows="8" // اختيار عدد الأسطر اختياري هنا
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("descriptioningerman")}  </label>
                    <textarea
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionDe}
                        onChange={handleDescriptionDeChange}
                        style={{ height: '300px' }} // تحديد الارتفاع باستخدام CSS
                        rows="8" // اختيار عدد الأسطر اختياري هنا
                    />
                </div>

                {/* حقل إدخال رابط الفيديو */}
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>{t("videoLink")}</label> {/* تعديل التسمية */}
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={videoURL}
                        onChange={handleVideoURLChange} // ربط قيمة الفيديو المسترجعة
                    />
                </div>

                <div className='mt-3'>
                    <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        ) : (
                            t("send")
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

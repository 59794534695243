import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/Context';

export default function ShowAdmin({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/admin/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>


        <div className='row'>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                    <p>  {t("name")} </p>
                    <p className='fw-bold'> {data?.name} </p>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                    <p>  {t("email")}</p>
                    <p className='fw-bold'> {data?.email} </p>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                    <p> {t("phone")} </p>
                    <p className='fw-bold'> {data?.phone} </p>
                </div>
            </div>
     

     
    
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                <img src={data?.image} alt="slider" className='w-100 sliderimg d-center' />
                </div>
            </div>
        </div>


    </>
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../../Context/Context';

export default function EditeGallery({ id }) {
  // حالات لتخزين القيم
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDe, setDescriptionDe] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // حالة للتحقق من حالة الإرسال
  let { url, lan } = useContext(UserContext)


  const handleImageChange = (e) => setImage(e.target.files[0]);

  // دالة لإرسال البيانات إلى الـ API
  const handleSubmit = async () => {
    setIsSubmitting(true); // تعيين حالة التحميل
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();

    formData.append('title_ar', titleAr);
    formData.append('title_en', titleEn);
    formData.append('title_de', titleDe);
    formData.append('description_ar', descriptionAr);
    formData.append('description_en', descriptionEn);
    formData.append('description_de', descriptionDe);
    if (image) formData.append('image', image);

    try {
      const response = await axios.post(`${url}/dashboard/galleries/${id}?lang=${lan}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          '_method': 'put'
        }
      });

      if (response.data.status === true) {
        toast.success(response.data.message);
      }
      console.log('Data updated successfully:', response.data.status);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsSubmitting(false); // إيقاف حالة التحميل
    }
  };

  return (
    <div className='container'>
      <div className='row'>


        <div className='mb-3 mt-3'>
          <label className='fw-bold'>صورة</label>
          <input
            type='file'
            className='form-control popupinput mt-2'
            onChange={handleImageChange}
          />
        </div>

        <div className='mt-3'>
          <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /> : 'إرسال'}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/Context';

export default function AddFaq({ id }) {
    // حالات لتخزين القيم
    const [titleAr, setTitleAr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleDe, setTitleDe] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // حالة للتحقق من حالة الإرسال
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    // دوال لتحديث حالة المدخلات عند تغييرها
    const handleTitleArChange = (e) => setTitleAr(e.target.value);
    const handleTitleEnChange = (e) => setTitleEn(e.target.value);
    const handleTitleDeChange = (e) => setTitleDe(e.target.value);
    const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
    const handleDescriptionEnChange = (e) => setDescriptionEn(e.target.value);
    const handleDescriptionDeChange = (e) => setDescriptionDe(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);

    // دالة لإرسال البيانات إلى الـ API
    const handleSubmit = async () => {
        setIsSubmitting(true); // تعيين حالة التحميل
        const token = localStorage.getItem("usertoken");
        const formData = new FormData();

        formData.append('question_ar', titleAr);
        formData.append('question_en', titleEn);
        formData.append('question_de', titleDe);
        formData.append('answer_ar', descriptionAr);
        formData.append('answer_en', descriptionEn);
        formData.append('answer_de', descriptionDe);

        try {
            const response = await axios.post(`${url}/dashboard/app-questions?lang=${lan}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });

            if (response.data.status === true) {
                toast.success(response.data.message);
            }
            console.log('Data updated successfully:', response.data.status);
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsSubmitting(false); // إيقاف حالة التحميل
        }
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("question_ar")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleAr}
                        onChange={handleTitleArChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>  {t("question_en")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleEn}
                        onChange={handleTitleEnChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("question_de")}</label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleDe}
                        onChange={handleTitleDeChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>  {t("answer_ar")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionAr}
                        onChange={handleDescriptionArChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>  {t("answer_en")}   </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionEn}
                        onChange={handleDescriptionEnChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t("answer_de")}  </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionDe}
                        onChange={handleDescriptionDeChange}
                    />
                </div>



                <div className='mt-3'>
                    <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        ) : (
                            t("send")
                        )}
                    </button>

                </div>
            </div>
        </div>
    );
}

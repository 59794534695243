import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';
import { CssBaseline, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/Context';
// import logo from "../../../Logo.png"
import logo from "../../../Img/logodash.png"

export default function Navbar({ toggleSidebar }) {
  let { changeLanguagee, lan } = useContext(UserContext); // Use context to access lan and changeLanguagee
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');
  const [isLoggingOut, setIsLoggingOut] = useState(false); // Add isLoggingOut state
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
  };

  useEffect(() => {
    i18n.changeLanguage(lan); // Update the language when the context language changes
  }, [lan]);

  const toggleTheme = () => {
    setMymode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      document.body.classList.toggle('dark-mode', newMode === 'dark'); // Toggle dark mode class on body
      return newMode;
    });
  };

  useEffect(() => {
    // Apply the saved theme mode on initial load
    if (mymode === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [mymode]);

  // Logout function with loading state
  const handleLogout = () => {
    setIsLoggingOut(true); // Set loading state to true
    setTimeout(() => {
      localStorage.removeItem('usertoken'); // Remove token from localStorage
      setIsLoggingOut(false); // Set loading state to false after logout
      navigate('/LoginDashboard'); // Redirect to login page
      window.location.reload(true);
    }, 2000); // Simulate a 2-second delay for logging out
  };

  return (
    <div className='container-fluid'>
      <nav className="navbar navbar-expand-lg navbar-light bg-sidbar">
        <div className="container-fluid d-flex justify-content-between align-items-center">
        
          {/* Right side - Sidebar toggle */}
          <div className="d-flex align-items-center">
            {/* Toggle sidebar */}
            <i
              className={`fa-solid ${isSidebarOpen ? 'fa-times' : 'fa-bars'} font-23`}
              onClick={handleToggle}
              style={{ cursor: 'pointer', marginRight: '10px' }}
            ></i>

<div className='mx-4'>
  
<img src={logo} className='h-60'/>
</div>

          </div>
          
          {/* Left side - Language selector */}
          <div className="d-flex">
            <Link className="navbar-brand mt-1 " to="#">
              <select value={lan} onChange={changeLanguagee} className='bg-color btn'> {/* Use changeLanguagee from context */}
                <option value="en">English</option>
                <option value="ar">العربية</option>
                <option value="de">Deutsch</option>
              </select>
            </Link>



            <div className="dropdown me-3 mt-2">
              <button
                className="btn bg-color dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Profile
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <Link className="dropdown-item" to="/profile"> {t("viewProfile")} </Link>
                </li>
                <li>
                  {/* Display loading spinner when logging out */}
                  <button className="dropdown-item" onClick={handleLogout} disabled={isLoggingOut}>
                    {isLoggingOut ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Logout'
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </nav>
    </div>
  );
}

import axios from 'axios';
import toast from 'react-hot-toast';
import { useContext, useState } from 'react'; // استيراد useState لإدارة حالة التحميل
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';

const DeletImgillness = ({ id, onDeleteSuccess }) => {
  const [isDeleting, setIsDeleting] = useState(false); // حالة للتحكم في التحميل
  const { t } = useTranslation();
  let {url , lan} = useContext(UserContext)
  const handleDelete = async () => {
    const token = localStorage.getItem("usertoken");
    setIsDeleting(true); // تفعيل حالة التحميل عند بدء الحذف

    try {
      const response = await axios.delete(`${url}/dashboard/galleries/${id}?lang=${lan}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // التحقق من نجاح عملية الحذف
      if (response?.data?.status === true) {
        toast.success("تم حذف العنصر بنجاح!"); // إظهار رسالة نجاح
        onDeleteSuccess(id); // تحديث البيانات بدون تحميل
      }
    } catch (error) {
      toast.error("حدث خطأ أثناء حذف العنصر."); // إظهار رسالة خطأ
      console.error("Error deleting the slider:", error);
    } finally {
      setIsDeleting(false); // إلغاء حالة التحميل بعد انتهاء الحذف
    }
  };

  return (
    <div className='d-flex justify-content-between'>
      <button
        onClick={handleDelete}
        className="btn btn-danger"
        disabled={isDeleting} // تعطيل الزر أثناء التحميل
      >
        {isDeleting ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // عرض مؤشر تحميل
        ) : (
          t("yes") // النص الافتراضي للزر
        )}
      </button>
      <button className="btn btn-secondary" disabled={isDeleting}>
      {t("no")} 
      </button>
    </div>
  );
};

export default DeletImgillness;

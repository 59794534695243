import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { ThreeCircles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import logo from '../../../../Img/3.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function LoginDashboard() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const { t } = useTranslation();
  let navigate = useNavigate();

  function Login(value) {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    axios.post(`https://api.aestheticdental.org/api/v1/dashboard/login?lang=ar`, value)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);

        if (response.data?.status === true) {
          localStorage.setItem("usertoken", response.data?.data?.token);
          toast.success("Welcome!");
          navigate("/slider");
          window.location.reload(true);
        } else {
          toast.error(response.data?.message || "Login failed, please try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        
        // Check if backend returned a specific error message
        const backendErrorMessage = err.response?.data?.message || "An error occurred, please try again.";
        setError(backendErrorMessage); // Set the error message from the backend

        toast.error(backendErrorMessage); // Optionally display the error message in a toast
      });
  }

  let validationSchema = yup.object({
    email: yup.string().email('يجب أن يكون بريدًا إلكترونيًا صالحًا').required('Email مطلوب'),
    password: yup.string().required('الرقم السري مطلوب')
  });

  let formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: Login
  });

  return (
    <div className='container-fluid bg-light'>
      <Helmet>
        <title> Login in Dashboard </title>
      </Helmet>
      <div className='row'>
        <div className='col-lg-6 col-md-6 d-center'>
          {isLoading ? (
            <div className='d-center mt-5'>
              <ThreeCircles
                visible={true}
                height="100"
                width="100"
                color="#00498d"
                ariaLabel="three-circles-loading"
              />
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} className='loginboxshado mx-auto w-75'>
              <div className='mt-5 px-3'>
                {/* Show backend error message */}
                {error && <div className='alert alert-danger p-3'>{error}</div>}

                <label htmlFor='email' className='mt-3'> {t("email")} :</label>
                <input
                  name='email'
                  id='email'
                  className='form-control mt-3 popupinput  '
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className='alert alert-danger'>{formik.errors.email}</div>
                )}

                <label htmlFor='password' className='mt-4'> {t("password")} :</label>
                <div className='input-group'>
                  <input
                    name='password'
                    id='password'
                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                    className='form-control popupinput mt-3 '
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      className='btn login mt-3'
                      onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    >
                      {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa fa-eye" aria-hidden="true"></i>}
                    </button>
                  </div>
                </div>
                {formik.errors.password && formik.touched.password && (
                  <div className='alert alert-danger'>{formik.errors.password}</div>
                )}

                <div className='d-flex mt-3 pb-3'>
                  <button
                    disabled={!(formik.isValid && formik.dirty) || isLoading}
                    className='btn bg-color'
                    type='submit'
                  >
                    {isLoading ? "Loading..." : t("login")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className='col-lg-6 col-md-6'>
          <div>
            <img src={logo} className='w-100 vh50' alt='Logo' />
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../Context/Context';
// import { UserContext } from '../../Context/Context';

export default function ShowFeatures({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/staticPage/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>


        <div className='row'>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("descriptioninarabic")} </p>
                    <textarea  rows='4' value={data?.description_ar} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.description_ar} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("titlearabic")}</p>
                    <input value={data?.title_ar} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.title_ar} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("descriptioninenglish")} </p>
                    <textarea  rows='4' value={data?.description_en} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.description_en} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p> {t("titleenglish")}</p>
                    <input value={data?.title_en} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.title_en} </p> */}
                </div>
            </div>


            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("descriptioningerman")}</p>
                    <textarea  value={data?.description_de} disabled className='form-control bg-input'  rows='4'>

</textarea>
                    {/* <p className='fw-bold'> {data?.description_de} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("totlegerman")}</p>
                    <input value={data?.title_de} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.title_de} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                <img src={data?.file} alt="slider" className='w-100 sliderimg d-center' />
                </div>
            </div>
        </div>


    </>
}

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import BarChartComponent from './Shart';

export default function HomeDashboard() {
  let { url, lan } = useContext(UserContext); // Accessing URL and language from context
  const { t } = useTranslation();
  const [data, setdata] = useState([])


  useEffect(() => {
   


  async  function getdata(){
    let token =  localStorage.getItem("usertoken")
    
      let {data} = await axios.get(`${url}/dashboard/home-admin` , {
        headers: {
          Authorization: `Bearer ${token}`, // Make sure to format the token properly if needed
        },
      });
      setdata(data?.data)
      
    }
    getdata()

  }, [])
  console.log(data);
  

  return (
    <div className='container mt-2'>
      <div className='row'>
        <div className='col-3'>
          <div className='bg-cart'>
            <div className='pt-2 text-center'>
              <p className='text-white'>  {t("reviews")} </p>
              <p className='pt-1 pb-2 text-white fw-bld'> {data?.reviewCount} </p>
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='bg-cart'>
            <div className='pt-2 text-center'>
              <p className='text-white'>  {t("doctorCount")} </p>
              <p className='pt-1 pb-2 text-white fw-bld'> {data?.doctorCount} </p>
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='bg-cart'>
            <div className='pt-2 text-center'>
              <p className='text-white'>  {t("serviceCount")} </p>
              <p className='pt-1 pb-2 text-white fw-bld'> {data?.serviceCount} </p>
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='bg-cart'>
            <div className='pt-2 text-center'>
              <p className='text-white'>  {t("contactCount")} </p>
              <p className='pt-1 pb-2 text-white fw-bld'> {data?.contactCount} </p>
            </div>
          </div>
        </div>
      </div>



<>
  <BarChartComponent/>
</>

    </div>
  )
}

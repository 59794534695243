import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../Context/Context';

export default function ShowReviews({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/review/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>


        <div className='row'>
            <div className='col-12 mt-3'>
                <div className='justify-content-between '>
                    <p className='fw-bold'>  {t("name")} </p>
                    <input value={data?.name} disabled className='form-control bg-input'/>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("email")}</p>
                    <input value={data?.email} disabled className='form-control bg-input'/>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("comment")} </p>
                    <textarea value={data?.comment} disabled className='form-control bg-input'  rows='4'></textarea>
                </div>
            </div>
    

        </div>


    </>
}

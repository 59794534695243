import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/Context';

export default function ShowFaq({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/app-questions/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>


        <div className='row'>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("question_ar")} </p>
                    <input value={data?.question_ar} disabled className='form-control bg-input'/>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("question_en")}</p>
                    <input value={data?.question_en} disabled className='form-control bg-input'/>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("question_de")} </p>
                    <input value={data?.question_de} disabled className='form-control bg-input'/>
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("answer_ar")}</p>
                    <input value={data?.answer_ar} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.answer_ar} </p> */}
                </div>
            </div>


            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("answer_en")}</p>
                    <input value={data?.answer_en} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.answer_en} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("answer_de")}</p>
                    <input value={data?.answer_de} disabled className='form-control bg-input'/>
                    {/* <p className='fw-bold'> {data?.answer_de} </p> */}
                </div>
            </div>
        </div>


    </>
}

import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/Context';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

export default function Replay({ id }) {
  const { t } = useTranslation();
  const { url, lan } = useContext(UserContext);
  const [isloading, setisloading] = useState(false);

  const handelReplay = async (values) => {
    const token = localStorage.getItem("usertoken");
    setisloading(true);

    try {
      const res = await axios.post(`${url}/dashboard/contact-us/send-mail/${id}?lang=${lan}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setisloading(false);
      toast.success(res.data.message || t("success")); // Ensure toast displays a valid message
    } catch (err) {
      setisloading(false);
      toast.error(err.response?.data?.message || t("erroroccurred")); // Display error message
    }
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      body: ""
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(t("subjectrequired")),
      body: Yup.string().required(t("bodyrequired")),
    }),
    onSubmit: handelReplay
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label>{t("subject")}</label>
          <input
            id="subject"
            className="form-control popupinput mt-3"
            placeholder={t("subject")}
            onBlur={formik.handleBlur}
            name="subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
          />
          {/* {formik.touched.subject && formik.errors.subject ? (
            <div className="error text-danger">{formik.errors.subject}</div>
          ) : null} */}
        </div>

        <div className="mt-3">
          <label>{t("body")}</label>
          <textarea
            id="body"
            cols={7}
            style={{ width: '100%', height: '200px' }} // Adjust width and height as needed
            onBlur={formik.handleBlur}
            className="form-control popupinput"
            name="body"
            value={formik.values.body}
            onChange={formik.handleChange}
          />
      
        </div>

        <button type="submit" className="btn bg-color mt-2" disabled={isloading}>
          {isloading ? t("sending") : t("send")}
        </button>
      </form>
    </>
  );
}

import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ThreeCircles } from 'react-loader-spinner';

import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import ShowResult from './ShowResult';
import UpdateResult from './UpdateResult';
// import UpdateImg from './UpdateImg';
// import ShsowSlider from './ShowAbout';
// import DeleteAbout from './DeleteAbout';
// import AddAbout from './AddAbout';
// import EditeAbout from './EditeAbout';
// import { UserContext } from '../../Context/Context';

const UpdateImg = () => {



    const [dataa, setdataa] = useState([])

    useEffect(() => {
        let token = localStorage.getItem("usertoken")
        console.log(token);


        async function getdata() {
            let data = await axios.get("https://api.aestheticdental.org/api/v1/dashboard/staticPage?lang=de", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                  },
            })
            console.log(data.data?.data?.image_result);
            // setdataa(data.data?.data?.image_result)

        }
        getdata()

    }, [])



  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [newSlider, setNewSlider] = useState({ title: '', description: '', image: null }); // حالة لحفظ بيانات العنصر الجديد
  const { t } = useTranslation();
  const [resposeimg, setresposeimg] = useState([])
  
  let { url, lan } = useContext(UserContext); // Accessing URL and language from context

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/staticPage?lang=${lan}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response?.data?.data?.result);
        setresposeimg(response?.data?.data)
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, lan]); // Trigger API call when 'url' or 'lan' changes
// console.log(data[4].file);

  const handleView = (item) => {
    setSelectedItem(item); // حفظ العنصر الذي سيتم تحريره أو حذفه
  };

  const handleViewDetails = (item) => {
    setViewItem(item); // حفظ العنصر الذي سيتم عرضه في نافذة العرض
  };

  const handleDeleteSuccess = (deletedId) => {
    setData(prevData => prevData.filter(item => item.id !== deletedId)); // تحديث البيانات بعد الحذف
  };

  const handleNewSliderChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setNewSlider({ ...newSlider, image: files[0] }); // حفظ الصورة
    } else {
      setNewSlider({ ...newSlider, [name]: value }); // حفظ العنوان والوصف
    }
  };
// console.log(resposeimg?.image_result?.file);

  const columns = [

    {
        name: t("image"),
        selector: row => (
          <img src={row?.file} alt="slider" className='w-imgUserslider d-center' />
        ),
        sortable: true,
      },
    {
      name: t("operations"),
      selector: row => (
        <>
          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#editModal"
            onClick={() => handleView(row)}
          >
            <i className="fas fa-edit"></i>
          </button>

          {/* <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            onClick={() => handleView(row)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button> */}

          <button
            className='btn btn-sm bg-color mx-1'
            data-bs-toggle="modal"
            data-bs-target="#viewDetailsModal"
            onClick={() => handleViewDetails(row)}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  if (isLoading) return (
    <div className='d-center mt-5'>
      <ThreeCircles
        visible={true}
        height="100"
        width="100"
        color="#00498d"
        ariaLabel="three-circles-loading"
      />
    </div>
  );

  if (isError) return <p>Error loading data.</p>;

  return (
    <div>
      <div className=''>
        {/* <button className='btn bg-color mt-3 mb-3' data-bs-toggle="modal" data-bs-target="#addNewModal">  {t("addslider")} </button> */}
      </div>
      <DataTable
        columns={columns}
        data={dataa}
        pagination
        selectableRows
        highlightOnHover
      />

      {/* نافذة منبثقة لإضافة عنصر جديد */}
      <div className="modal fade" id="addNewModal" tabIndex="-1" aria-labelledby="addNewModalLabel" aria-hidden="true">
        <div className="modal-dialog">


          <div className="modal-content">

          
            <div className="modal-header">
              <h5 className="modal-title" id="addNewModalLabel">  {t("addslider")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>


            <div className="modal-body">
              {/* <AddAbout /> */}
            </div>
          </div>
        </div>
      </div>

      {/* باقي النوافذ المنبثقة (تحرير، عرض، حذف) */}
      <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">   {t("edittheitem")}    </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedItem && <UpdateResult id={selectedItem.id} />}
            </div>
          </div>
        </div>
      </div>
      {/* <UpdateImg/> */}

      <div className="modal fade" id="viewDetailsModal" tabIndex="-1" aria-labelledby="viewDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewDetailsModalLabel">  {t("ttemdetails")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {viewItem && <ShowResult id={viewItem.id} />}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">  {t("doyoudelete")}   </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* {selectedItem && <DeleteAbout id={selectedItem.id} onDeleteSuccess={() => handleDeleteSuccess(selectedItem.id)} />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateImg;

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing, ThreeCircles } from 'react-loader-spinner';
import { UserContext } from '../../Context/Context';
import { useTranslation } from 'react-i18next';

export default function ShowSlider({ id }) {
  // حالات لتخزين القيم
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDe, setDescriptionDe] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // حالة للتحقق من حالة الإرسال
  let { url, lan } = useContext(UserContext)
  const { t } = useTranslation();

  // دالة جلب البيانات
  const getdata = async () => {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(`${url}/dashboard/sliders/${id}?lang=${lan}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.data;
  };

  // استخدام useQuery لجلب البيانات
  const { data, isError, isLoading } = useQuery(["getshowslider", id], getdata, {
    onSuccess: (data) => {
      setTitleAr(data.title_ar || '');
      setTitleEn(data.title_en || '');
      setTitleDe(data.title_de || '');
      setDescriptionAr(data.description_ar || '');
      setDescriptionEn(data.description_en || '');
      setDescriptionDe(data.description_de || '');
    },
  });

  // عرض حالة التحميل أو الخطأ
  if (isLoading) return <div className='d-center mt-5'>
    <ThreeCircles
      visible={true}
      height="100"
      width="100"
      color="#00498d"
      ariaLabel="three-circles-loading"
    />
  </div>;
  if (isError) return <p><div className='alert alert-danger'>Not  internet connection</div>.</p>;

  // دوال لتحديث حالة المدخلات عند تغييرها
  const handleTitleArChange = (e) => setTitleAr(e.target.value);
  const handleTitleEnChange = (e) => setTitleEn(e.target.value);
  const handleTitleDeChange = (e) => setTitleDe(e.target.value);
  const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
  const handleDescriptionEnChange = (e) => setDescriptionEn(e.target.value);
  const handleDescriptionDeChange = (e) => setDescriptionDe(e.target.value);
  const handleImageChange = (e) => setImage(e.target.files[0]);

  // دالة لإرسال البيانات إلى الـ API
  const handleSubmit = async () => {
    setIsSubmitting(true); // تعيين حالة التحميل
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();

    formData.append('title_ar', titleAr);
    formData.append('title_en', titleEn);
    formData.append('title_de', titleDe);
    formData.append('description_ar', descriptionAr);
    formData.append('description_en', descriptionEn);
    formData.append('description_de', descriptionDe);
    if (image) formData.append('image', image);

    try {
      const response = await axios.post(`${url}/dashboard/sliders/${id}?lang=${lan}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          '_method': 'put'
        }
      });

      if (response.data.status === true) {
        toast.success(response.data.message);
      }
      console.log('Data updated successfully:', response.data.status);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsSubmitting(false); // إيقاف حالة التحميل
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("namearbic")} </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={titleAr}
            onChange={handleTitleArChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("nameenglesh")} </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={titleEn}
            onChange={handleTitleEnChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("namegerman")} </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={titleDe}
            onChange={handleTitleDeChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>  {t("descriptioninarabic")}  </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={descriptionAr}
            onChange={handleDescriptionArChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>   {t("descriptioninenglish")}  </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={descriptionEn}
            onChange={handleDescriptionEnChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>  {t("descriptioningerman")}  </label>
          <input
            type='text'
            className='form-control popupinput mt-2'
            value={descriptionDe}
            onChange={handleDescriptionDeChange}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("image")} </label>
          <input
            type='file'
            className='form-control popupinput mt-2'
            onChange={handleImageChange}
          />
        </div>

        <div className='mt-3'>
          <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /> : 'إرسال'}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { UserContext } from '../Context/Context';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// تفعيل العناصر الأساسية في Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = () => {
    let { url, lan } = useContext(UserContext); // Accessing URL and language from context
    const { t } = useTranslation();
    const [date, setdata] = useState([])




    useEffect(() => {
        async function getdata() {

            const token = localStorage.getItem("usertoken")
            let { data } = await axios.get(`${url}/dashboard/home-admin`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Make sure to format the token properly if needed
                }
            })
            setdata(data?.data)
        }
        getdata()
    }, [])

    console.log(date);



    // البيانات للمخطط
    const data = {
        labels: [t("contactCount"), t("serviceCount"), t("doctorCount"), t("reviews")], // تسميات المحور الأفقي
        datasets: [
            {
                label: ' ', // تسمية بيانات المخطط
                data: [date?.reviewCount, date?.serviceCount, date?.doctorCount, date?.reviewCount], // بيانات المحور العمودي
                backgroundColor: '#00498d', // لون الأعمدة
                barPercentage: 0.5, // نسبة عرض العمود مقارنةً بالفئة
                categoryPercentage: 0.5, // نسبة عرض العمود مقارنةً بالمسافة المتاحة
            },
        ],
    };

    // خيارات تخصيص المخطط
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top', // موضع الأسطورة
            },
            title: {
                display: true,
                text: 'إحصائيات  لوحة التحكم', // عنوان المخطط
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // إخفاء الخطوط الشبكية على المحور الأفقي
                },
            },
            y: {
                beginAtZero: true, // بدء المحور العمودي من الصفر
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChartComponent;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://api.aestheticdental.org/api/v1/dashboard/login-admin?lang=en', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('usertoken')}`
          }
        });
        setUser(response.data.data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (isLoading) {
    return (
      <div className='d-center mt-5'>
        <ThreeCircles
          visible={true}
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="three-circles-loading"
        />
      </div>
    );
  }

  if (error) {
    return <div className='alert alert-danger'>{error}</div>;
  }

  return (
  <div className=' mx-auto'>
      <div className='container  px-5 pt-3 loginboxshado w-50 text-start'>
      <h2 className='text-color pt-2'>  {t("Profile")}  </h2>
      {user ? (
        <div className='profile-details row mt-4'>
        <div className='col-12'>
        <p><strong> {t("name")}  :</strong> {user.name}</p>
        </div>
        <div className='col-12'>
        <p><strong>{t("email")} :</strong> {user.email}</p>
        </div>
        <div className='col-12 '>
        <p><strong>    {t("phone")} :</strong> {user.phone}</p>
        </div>
        <div className='col-12'>
        <p><strong>{t("image")}:</strong> <img src={user.image} className='w-imgUserprofile'/>  </p>
        </div>
        
         
    
          
    
        </div>
      ) : (
        <p>No user data available.</p>
      )}
    </div>
  </div>
  );
}

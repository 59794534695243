import axios from 'axios';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/Context';

export default function ShowStting({ id }) {
    const { t } = useTranslation();
    let {url , lan} = useContext(UserContext)

    const getdata = async () => {
        const token = localStorage.getItem("usertoken");
        const response = await axios.get(`${url}/dashboard/setting?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data;
    };
    const { data, isError, isLoading } = useQuery(["getshowsliderDetales", id], getdata)

    console.log(data);


    return <>
        <div className='row'>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("phone")} </p>

                    <input value={data?.phone} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.phone} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("email")}</p>
                    <input value={data?.email} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.email} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'>  {t("locationar")}</p>
                    <input value={data?.location_ar} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.phone} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("location_en")} </p>
                    <input value={data?.location_en} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.speciality_ar} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("location_de")} </p>
                    <input value={data?.location_de} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.speciality_en} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("fecabook")} </p>
                    <input value={data?.fecabook} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.speciality_de} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("instgram")}</p>
                    <input value={data?.instgram} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.speciality_en} </p> */}
                </div>
            </div>


            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("twitter")}</p>
                    <input value={data?.twitter} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.description_de} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className=' justify-content-between '>
                    <p className='fw-bold'> {t("yotube")}</p>
                    <input value={data?.yotube} disabled className='form-control bg-input'/>

                    {/* <p className='fw-bold'> {data?.description_de} </p> */}
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                <img src={data?.service_image} alt="slider" className='w-100 sliderimg d-center' />
                </div>
            </div>
            <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between '>
                <img src={data?.result_image} alt="slider" className='w-100 sliderimg d-center' />
                </div>
            </div>
        </div>


    </>
}

import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../../Context/Context';
import { useTranslation } from 'react-i18next';

export default function EditeStting({ id }) {
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDe, setDescriptionDe] = useState('');
  const [specialityAr, setSpecialityAr] = useState('');
  const [specialityEn, setSpecialityEn] = useState('');
  const [specialityDe, setSpecialityDe] = useState('');
  const [image, setImage] = useState(null);
  const [service_image, setservice_image] = useState(null);
  const [result_image, setresult_image] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { url, lan } = useContext(UserContext);
  const { t } = useTranslation();
  const getData = async () => {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(`${url}/dashboard/setting?lang=${lan}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response?.data?.data;
  };

  const { data, isError, isLoading } = useQuery(["getDoctor", id], getData, {
    onSuccess: (data) => {
      setTitleAr(data.phone || '');
      setTitleEn(data.email || '');
      setTitleDe(data.fecabook || '');
      setDescriptionAr(data.instgram || '');
      setDescriptionEn(data.twitter || '');
      setDescriptionDe(data.yotube || '');
      setSpecialityAr(data.location_ar || '');
      setSpecialityEn(data.location_en || '');
      setSpecialityDe(data.location_de || '');
      // setresult_image(data.result_image || '');
    },
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading data.</p>;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();
  
    formData.append('phone', titleAr);
    formData.append('email', titleEn);
    formData.append('fecabook', titleDe);
    formData.append('location_ar', descriptionAr);
    formData.append('location_en', specialityAr);
    formData.append('location_de', specialityDe);
    formData.append('location_en', specialityEn);
    formData.append('instgram', descriptionEn);
    formData.append('twitter', descriptionDe);
    if (result_image) formData.append('result_image', result_image); // Append once
    if (image) formData.append('logo', image);
    if (service_image) formData.append('service_image', service_image);
  




    try {
      const response = await axios.post(`${url}/dashboard/setting/update?lang=${lan}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          '_method': 'put'
        }
      });
  
      if (response.data.status) {
        toast.success(response.data.message);
      }
      console.log('Data updated successfully:', response.data.status);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("phone")} </label>
          <input
            type='text'
            className='form-control mt-2'
            value={titleAr}
            onChange={(e) => setTitleAr(e.target.value)}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("email")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={titleEn}
            onChange={(e) => setTitleEn(e.target.value)}
          />
        </div>




        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("locationar")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityAr}
            onChange={(e) => setSpecialityAr(e.target.value)}
          />
        </div>



        <div className='mb-3 mt-3'>
          <label className='fw-bold'>  {t("location_en")} </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityEn}
            onChange={(e) => setSpecialityEn(e.target.value)}
          />
        </div>



        <div className='mb-3 mt-3'>
          <label className='fw-bold'>  {t("location_de")} </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityDe}
            onChange={(e) => setSpecialityDe(e.target.value)}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>{t("fecabook")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={titleDe}
            onChange={(e) => setTitleDe(e.target.value)}
          />
        </div>





        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("instgram")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={descriptionAr}
            onChange={(e) => setDescriptionAr(e.target.value)}
          />
        </div>




        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("twitter")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.target.value)}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>{t("yotube")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={descriptionDe}
            onChange={(e) => setDescriptionDe(e.target.value)}
          />
        </div>

        {/* <div className='mb-3 mt-3'>
          <label className='fw-bold'>logo</label>
          <input
            type='file'
            className='form-control mt-2'
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div> */}
        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("service_image")} </label>
          <input
            type='file'
            className='form-control mt-2'
            onChange={(e) => setservice_image(e.target.files[0])}
          />
        </div>
        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("result_image")} </label>
          <input
  type='file'
  className='form-control mt-2'
  onChange={(e) => setresult_image(e.target.files[0])} // تأكد أن الصورة الجديدة يتم تعيينها هنا
/>

        </div>

        <div className='mt-3'>
          <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
            ) : (
              'إرسال'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

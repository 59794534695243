import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

let queryClient = new QueryClient();

const NetworkAlert = () => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    isOffline && (
      <div className="alert alert-danger text-center p-5" role="alert" style={{ fontSize: '24px' }}>
        No Internet Connection! Please check your network.
      </div>
    )
  );
};

root.render(
  <div>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <NetworkAlert />
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </div>
);

reportWebVitals();
